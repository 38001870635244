import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Cookies } from "react-cookie-consent";

import myStore from "../mobX/Store";

export function cookieExist(name) {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();

    if (cookie.startsWith(name + "=")) {
      return cookie.substring(name.length + 1);
    }
  }

  return null;
}

export default function useRoutes() {
  const location = useLocation();

  useEffect(() => {
    const parameters = location.search.substring(1).split("&");

    if (parameters.includes("tlv-segmented"))
      myStore.updateSegmentedValue(true);
    else myStore.updateSegmentedValue(cookieExist("tlv-segmented"));

    if (parameters.includes("allow-countries")) {
      Cookies.set("CodereAcceptCountries", "true", {
        domain: ".codere.com.co",
      });

      console.log("CodereAcceptCountries added");
    }
  }, []);
}
