import { observer } from "mobx-react";

// Codere StickyFooter Footer \\
const StickyFooter = observer(() => {
  const data = [
    {
      url: "",
      text: "deportes",
      icon: null,
    },
    {
      url: "",
      text: "directo",
      icon: null,
    },
    {
      url: "",
      text: "casino",
      icon: null,
    },
  ];

  const segmented_data = [
    {
      url: "",
      text: "deportes",
      icon: {},
    },
    {
      url: "",
      text: "deportes",
      icon: {},
    },
    {
      url: "",
      text: "deportes",
      icon: {},
    },
  ];

  return (
    <div className="sticky-footer-contatiner">
      <a
        href="https://m.codere.com.co/deportesCol/#/HomePage"
        className="sticky-item"
        rel="nofollow"
      >
        DEPORTES
      </a>
      <a
        href="https://m.codere.com.co/deportesCol/#/casino/CasinoPage?lobby=Colombia"
        className="sticky-item"
        rel="nofollow"
      >
        ★ CASINO ★
      </a>
      <a
        href="https://m.codere.com.co/deportesCol/#/DirectosPage"
        className="sticky-item"
        rel="nofollow"
      >
        EN VIVO
      </a>
    </div>
  );
});

export default StickyFooter;
